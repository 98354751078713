* {
  padding: 0;
  margin: 0;
}
.HomeMain {
  background-image: radial-gradient(circle, #4c4c4c, #3a3a3a, #282828, #181818, #000000);
  height: 33vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #00f2ea;
}
.Loader {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.HomeSecond {
  display: flex;
  align-items: center;
}
.HomeMain h1 {
  text-align: center;
  color: #ff004f;
  padding-top: 30px;
}
.HomeMain input {
  height: 48px;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 1.3rem;
  padding-left: 15px;
  border: 1px solid black;
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.HomeMain button {
  height: 48px;
  border-radius: 10px;
  width: 50px;
  border: none;
  background-color: #ffffff;
  margin-left: 20px;
}
.GoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 46px;
  width: 50px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #ffffff;
}
.EarningShow {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.HomeMain p {
  padding-top: 10px;
  margin-bottom: 20px;
  text-align: justify;
  color: #ffffff;
  text-align: center;
}
.Footer {
  background-color: #000000;
  border-top: 2px solid #00f2ea;
  text-align: center;
  color:#ffffff;
  max-height:60px;
  line-height: 30px;
  margin: 0;
  clear: both;
  bottom: auto;
  width:100%;
  position: relative;
  margin-top: 15px;
}
@media (max-width: 1120px) {
  .OurCards {
    flex-direction: column;
  }
}
@media (max-width: 720px) {
  .CardFix {
    flex-direction: column;
  }
}

@media (max-width: 475) {
  .HomeMain input {
    height: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 1rem;
    padding-left: 5px;
    border: 1px solid black;
    outline: none;
    width: 70%;
  }
  .HomeMain {
    /* background: linear-gradient(97.31deg, #EE6501 57.81%, #FBA600 107.41%); */

    height: 50vh;
  }
}

@media (max-width: 400px) {
  .HomeSecond {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .HomeMain input {
    margin-bottom: 10px;
  }
  .GoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 36px;
    width: 40px;
    margin-left: 10px;
    cursor: pointer;
    background-color: #ffffff;
  }
  .HomeMain {
    /* background: linear-gradient(97.31deg, #EE6501 57.81%, #FBA600 107.41%); */

    height: 45vh;
  }
  .HomeMain p {
    padding-top: 10px;
    color: #ffffff;
    text-align: center;
  }
}
