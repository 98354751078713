.OurCards {
  margin: auto;
  width: 90%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CardsMain h1 {
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  /* max-width: 1024px; */
}
.CardFix {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1120px) {
  .OurCards {
    flex-direction: column;
  }
}
@media (max-width: 720px) {
  .CardFix {
    flex-direction: column;
  }
}
@media (min-width: 1670px) {
  .OurCards {
    margin: auto;
    width: 100%;
    /* max-width: 1024px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
