.SignMain {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.SignSecond {
  min-width: 300px;
  width: 90%;
  margin: auto;
}
.SignMain h1 {
  text-align: center;
  padding-top: 10px;
}
.SignMain input {
  border: none;
  font-size: 1.1rem;
  outline: none;
  line-height: 30px;
  border-bottom: 1px solid blueviolet;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.SignMain button {
  display: block;
  text-align: center;
  height: 40px;
  width: 80px;
  background-color: #18207e;
  color: #ffffff;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  outline: none;
  border-radius: 30px;
}
