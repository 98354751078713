.CardEditMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.CardEditSecond {
  width: 500px;
  min-width: 300px;
  border-radius: 2px;
  background-color: #ffffff;
  -webkit-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  -moz-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
}
.EditCardImage img {
  height: 300px;
  width: 100%;
  min-width: 300px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.EditCardHeading {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.CardEditMain input {
  width: 90%;
  height: 30px;
  background-color: #ffffff;
  font-size: 1.3rem;
  padding-left: 15px;
  border: 1px solid black;
  outline: none;
}
.CardEditMain textarea {
  width: 90%;
  height: 60px;
  background-color: #ffffff;
  font-size: 1.3rem;
  padding-left: 15px;
  border: 1px solid black;
  outline: none;
}
.CardEditMain button {
  display: block;
  text-align: center;
  height: 35px;
  width: 70px;
  background-color:#ff004f;
  color: #ffffff;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
