.AuthMain {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.AuthSecond {
  width: 40%;
  min-width: 320px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  -webkit-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  -moz-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
}
@media (max-width: 475) {
  .AuthSecond {
    width: 90%;
  }
}
