* {
  padding: 0;
  margin: 0;
}
.EarningShowMain {
  width: 80%;
  max-width: 960px;
  min-width: 300px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  -webkit-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  -moz-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
}
.EarningShowSecond {
  width: 90%;
  margin: auto;
  min-width: 300px;
}

.EarningShowMain h1 {
  margin: 10px 0px;
  font-weight: 500;
  text-align: center;
}
.divMain {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  width: 95%;
  margin: auto;
  margin-bottom: 10px;
  font-size: 1.3rem;
  border-bottom: 1px solid #c3c3c3;
}
.divHeader {
  font-weight: 500;
}
.divHeader span {
  font-size: 1rem;
  color: #c3c3c3;
}
