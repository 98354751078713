.EnterValueMain h1 {
  text-align: center;
  margin-top: 10px;
}
.EnterValueMain input {
  border: none;
  font-size: 1.1rem;
  outline: none;
  line-height: 30px;
  border-bottom: 1px solid blueviolet;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}
.EnterValuedivMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}
.EnterValueSecond {
  width: 46%;
  padding: 2%;
  -webkit-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  -moz-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  min-width: 300px;
  margin: auto;
}
.EnterValueddivHeader {
  width: 40%;
  font-weight: 500;
}
.EnterValueddivValue {
  width: 30%;
  display: flex;
  align-items: center;
}
.EnterValueMain button {
  display: block;
  text-align: center;
  height: 40px;
  width: 80px;
  background-color: #ff004f;
  color: #ffffff;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  outline: none;
  border-radius: 30px;
}
.buttonParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
