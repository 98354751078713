.CardImage img {
  height: 125px;
  min-width: 300px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.CardMain h3 {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CardMain {
  width: 300px;
  border-radius: 2px;
  min-width: 300px;
  background-color: #ffffff;
  -webkit-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  -moz-box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  box-shadow: -8px -11px 35px -1px rgba(195, 195, 195, 1);
  margin-left: 40px;
  margin-top: 40px;
}
.CardContent {
  width: 90%;
  margin: auto;
}
.CardMain button {
  display: block;
  text-align: center;
  height: 35px;
  width: 70px;
  background-color: #ff004f;
  color: #ffffff;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
.CardMain a{
    text-decoration: none;
}
@media (max-width: 720px) {
  .CardMain {
    margin-left: 0px;
  }
}
